// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.research-page-body{
    padding: 5% 20%;
}

.research-page-body > h3{
    font-size: 2rem;
    font-weight: 600;
    color: var(--people-header);
}

.research-page-body > h4{
    margin-top: 10%;
    color: var(--people-header);
}
`, "",{"version":3,"sources":["webpack://./src/style/ResearchsPages.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":["\n.research-page-body{\n    padding: 5% 20%;\n}\n\n.research-page-body > h3{\n    font-size: 2rem;\n    font-weight: 600;\n    color: var(--people-header);\n}\n\n.research-page-body > h4{\n    margin-top: 10%;\n    color: var(--people-header);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
