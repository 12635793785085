// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10% 10%;
}

.column {
    flex: 1 1;
    margin: auto 10px;
}

.card {
    background-color: var(--bg-main);
    padding: 15px;
    margin-bottom: 10px;
    transition: all 0.5s;
    border: none;
    cursor: pointer;
}

.card:hover{
    transform: translateY(-0.4vmax);
    box-shadow: 0 6px 15px var(--card-hover);
}

.card > img{
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 2/1;
}

.card > h5, .card > span{
    text-align: left;
}

.card > h5{
    margin: 5% 0;
    color: var(--people-header);
}

.card > span{
    font-family: 'Montserrat', sans-serif;
    color: var(--p-color);
}
`, "",{"version":3,"sources":["webpack://./src/style/Research.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,+BAA+B;IAC/B,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;IACrC,qBAAqB;AACzB","sourcesContent":[".card-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    margin: 10% 10%;\n}\n\n.column {\n    flex: 1;\n    margin: auto 10px;\n}\n\n.card {\n    background-color: var(--bg-main);\n    padding: 15px;\n    margin-bottom: 10px;\n    transition: all 0.5s;\n    border: none;\n    cursor: pointer;\n}\n\n.card:hover{\n    transform: translateY(-0.4vmax);\n    box-shadow: 0 6px 15px var(--card-hover);\n}\n\n.card > img{\n    width: 100%;\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    aspect-ratio: 2/1;\n}\n\n.card > h5, .card > span{\n    text-align: left;\n}\n\n.card > h5{\n    margin: 5% 0;\n    color: var(--people-header);\n}\n\n.card > span{\n    font-family: 'Montserrat', sans-serif;\n    color: var(--p-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
