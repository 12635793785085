// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.not-found{
    margin-top: 2%;
    width: 100%;
    height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found img{
    transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/style/NotFound.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["\n.not-found{\n    margin-top: 2%;\n    width: 100%;\n    height: 150%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.not-found img{\n    transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
