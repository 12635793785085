// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container-contact{
    margin: auto;
    padding: 7% 15%;
    text-align: center;
}

.grid-contain-cl{
    display: grid; 
    grid-template-columns: 1fr 1fr;
}

.ca-block1{
    width: 100%;
    margin: 3rem 1rem;
    text-align: left;
    display: flex;
    flex-direction: row;
}


.ca-block1 h5{
    color: var(--people-header)
}

.contact-people .contact-svg, .ca-block1 .contact-svg{
    margin-top: 10px;
    padding: 10px;
    color: var(--contact-svg);
}
`, "",{"version":3,"sources":["webpack://./src/style/ContactAffiliations.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI;AACJ;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":["\n.container-contact{\n    margin: auto;\n    padding: 7% 15%;\n    text-align: center;\n}\n\n.grid-contain-cl{\n    display: grid; \n    grid-template-columns: 1fr 1fr;\n}\n\n.ca-block1{\n    width: 100%;\n    margin: 3rem 1rem;\n    text-align: left;\n    display: flex;\n    flex-direction: row;\n}\n\n\n.ca-block1 h5{\n    color: var(--people-header)\n}\n\n.contact-people .contact-svg, .ca-block1 .contact-svg{\n    margin-top: 10px;\n    padding: 10px;\n    color: var(--contact-svg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
