// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
footer{
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    background-color: var(--bg-main);
    top: 0;
    padding: 0 4rem;
    box-shadow: 5px 0 5px var(--bg-text);
    z-index: 100;
}

.footerLink{
    padding: 3% 7% 2%;
}

.footerLink > ul{
    padding: 0;
    display: flex;
    flex-direction: row;
}

.footerLink li{
    margin: 5px 15px;
}


.footerLink ul svg path{
    fill: var(--link-color);
}

.footerLink ul svg:hover path{
    fill: var(--nav-link-hover);
}

.copyrightFooter{
    padding-bottom: 2%;
}

.copyrightFooter > p{
    opacity: 0.5;
}

.footerSC{
    width: 28px;
    height: 32px;
}


.footerSC:hover{
    filter: opacity(1) drop-shadow(0 0 0 blue);
}`, "",{"version":3,"sources":["webpack://./src/style/footer.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,gCAAgC;IAChC,MAAM;IACN,eAAe;IACf,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,0CAA0C;AAC9C","sourcesContent":["\nfooter{\n    margin-top: 2%;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    position: sticky;\n    background-color: var(--bg-main);\n    top: 0;\n    padding: 0 4rem;\n    box-shadow: 5px 0 5px var(--bg-text);\n    z-index: 100;\n}\n\n.footerLink{\n    padding: 3% 7% 2%;\n}\n\n.footerLink > ul{\n    padding: 0;\n    display: flex;\n    flex-direction: row;\n}\n\n.footerLink li{\n    margin: 5px 15px;\n}\n\n\n.footerLink ul svg path{\n    fill: var(--link-color);\n}\n\n.footerLink ul svg:hover path{\n    fill: var(--nav-link-hover);\n}\n\n.copyrightFooter{\n    padding-bottom: 2%;\n}\n\n.copyrightFooter > p{\n    opacity: 0.5;\n}\n\n.footerSC{\n    width: 28px;\n    height: 32px;\n}\n\n\n.footerSC:hover{\n    filter: opacity(1) drop-shadow(0 0 0 blue);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
